export enum Technology {
    Angular = "Angular",
    AWS = "AWS",
    Bash = "Bash",
    Cucumber = "Cucumber",
    CSharp = "C#",
    Express = "Express",
    Figma = "Figma",
    Git = "Git",
    GraphQL = "GraphQL",
    Handlebars = "Handlebars",
    Heroku = "Heroku",
    Java = "Java",
    JavaScript = "JavaScript",
    Jest = "Jest",
    JUnit = "JUnit",
    MongoDB = "MongoDB",
    Node = "Node",
    PostgreSQL = "PostgreSQL",
    Python = "Python",
    Quarkus = "Quarkus",
    React = "React",
    Redux = "Redux",
    Remix = "Remix",
    RxJS = "RxJS",
    SocketIO = "Socket.IO",
    SpringBoot = "Spring Boot",
    Tableau = "Tableau",
    TypeScript = "TypeScript",
    Vue = "Vue",
}
